<template>
  <div>
    <h5
      class="
        is-uppercase
        has-text-weight-medium has-text-centered has-text-primary
        p-2
        mb-2
        has-background-light
      "
    >
      Oftalmologistas
    </h5>
    <div class="block is-flex is-justify-content-space-between">
      <b-radio
        @input="onFilterChange()"
        v-model="status"
        name="status"
        native-value="active"
      >
        Ativos
        <span v-show="status == 'active'" class="tag is-info">{{ total }}</span>
      </b-radio>
      <b-radio
        @input="onFilterChange()"
        v-model="status"
        name="status"
        native-value="inactive"
      >
        Inativo
        <span v-show="status == 'inactive'" class="tag is-info">{{
          total
        }}</span>
      </b-radio>
      <b-radio
        @input="onFilterChange()"
        v-model="status"
        name="status"
        native-value="subscriber"
      >
        Assinantes
        <span v-show="status == 'subscriber'" class="tag is-info">{{
          total
        }}</span>
      </b-radio>
    </div>

    <b-table
      :data="data"
      :columns="columns"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      @click="setDoctor"
      focusable
    >
    </b-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selected: {},
      status: "active",
      data: [],
      total: 0,
      perPage: 15,
      page: 1,
      columns: [
        //treatment name crm rqe offices.address pages
        {
          field: "name",
          label: "Nome",
        },
        {
          field: "crm",
          label: "CRM",
        },
        {
          field: "rqe",
          label: "RQE",
        },
      ],
    };
  },
  mounted() {
    this.loadAsyncData();
  },
  methods: {
    onFilterChange() {
      this.page = 1;
      this.loadAsyncData();
    },
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    xxxchangeStatus(status) {
      let data;
      if (status == "active") data = { active: true };
      if (status == "subscriber") data = { subscriber: true };
      this.$http
        .put(`${process.env.VUE_APP_URI}doctor/${this.selected._id}`, data)
        .then(() => this.loadAsyncData());
    },
    loadAsyncData() {
      //active/:perPage/:page/:subscriber
      const params = [this.status, this.perPage, this.page].join("/");
      this.$http
        .get(`${process.env.VUE_APP_URI}doctor/${params}`, this.doctor)
        .then((response) => {
          this.total = response.data.total;
          this.data = response.data.data;
        });
    },
    setDoctor(v) {
      this.$router.push(`/doctor/${v._id}`);
    },
  },
};
</script>